export default {
  page: {
    title: {
      base: 'Pasarela de pago Autopay',
      start: 'Elección del método de pago',
      visadata: 'Visa Mobile',
      continue: 'Continuar - Elegir un método de pago',
      wait: 'Expectativa',
      confirmation: 'Confirmación de pago',
      thankYou: 'Gracias por el pago',
      error: 'Error de página',
      errorNotFound: 'Página no encontrada',
      secureBack: 'Cargando página',
      maintenance: 'Sitio web bajo mantenimiento',
      regulationsApproval: 'Reglamento y Política de Privacidad',
      toTransferData: 'datos de transferencia',
      sessionTimeout: 'Página después del final de la sesión',
      paywayError: 'Canal de pago no disponible'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Aumentar el tamaño de la fuente',
      decreaseFontSize: 'Reducir el tamaño de la fuente',
      changeContrast: 'Cambiar el contraste',
      changeLanguage: 'Cambiar el idioma del sitio',
      skipToContent: 'Pasar al contenido',
      skipToContentLabel: 'Pasar al contenido principal',
      logoTitle: 'logotipo del sitio'
    },
    sessionTimer: {
      timeOut: 'La sesión ha caducado',
      sessionEnd: 'La sesión finalizará en:',
      label: 'Extender el periodo de sesión',
      button: 'Extender'
    }
  },
  footer: {
    text1: '¿Tienes más preguntas sobre los pagos en línea u otros servicios nuestros? <a title="Se abre en una nueva ventana" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" target="_blank" rel="noreferrer">Ir a la página de ayuda</a>',
    text2: {
      part1: 'El Responsable del Tratamiento de tus datos personales es Autopay S.A.',
      part2: 'ul. Powstańców Warszawy 6, 81-718 Sopot. El suministro de los datos personales es voluntario, pero necesario para procesar la solicitud presentada. La base jurídica, la finalidad, el período de tratamiento de los datos personales y los derechos del Usuario, así como otra información importante relativa a los principios del tratamiento de los datos personales se detallan en <a href="{privacyPolicyUrl}" target="_blank" title="Ir a la página con el documento de Política de Privacidad (128 KB, PDF)" rel="noreferrer">la política de privacidad de Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Mostrar información adicional',
    linkShowMoreText: 'Lee más',
    linkShowLessTitle: 'Ocultar información adicional',
    linkShowLessText: 'Lee menos'
  },
  paywall: {
    header: 'Selecciona el método de pago',
    info: {
      paywayNotAvailable: 'Lo sentimos, parece que el método de pago seleccionado no está disponible en tu dispositivo en este momento.',
      applePayNotAvailable: 'Lo sentimos, parece que el pago con Apple Pay no está disponible en tu dispositivo en este momento.',
      googlePayNotAvailable: 'Lo sentimos, parece que el pago con Google Pay no está disponible en tu dispositivo en este momento.'
    },
    paywayGroup: {
      inactiveMessage: 'El pago no está disponible actualmente',
      notice: {
        novelty: 'NUEVO',
        promotion: 'OFERTA ESPECIAL',
        recommended: 'RECOMENDADO',
        mastercard: 'RÁPIDO Y SEGURO',
        mastercardC2P: 'RÁPIDO Y SEGURO',
        blik: 'BLIKOMANIA - ¡Regístrate y gana diamantes!',
        visaMobile: 'Paga de manera fluida y segura'
      },
      bank: {
        many: 'bancos',
        one: 'banco',
        two: 'bancos'
      },
      wallet: {
        many: 'monederos',
        one: 'monedero',
        two: 'monederos'
      },
      otp: {
        many: 'maneras',
        one: 'forma',
        two: 'maneras'
      },
      blikPayLater: {
        info: 'Saber más'
      }
    },
    paywayList: {
      label: 'Grupo de tipos de pago',
      linkSelectOther: 'Seleccionar otro'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Tengo una cuenta en otro banco'
    },
    paywaySwitch: {
      methodShortSingle: 'Desechable',
      methodLongSingle: 'Pagos únicos',
      methodShortAuto: 'Cíclico',
      methodLongAuto: 'Pagos recurrentes'
    },
    form: {
      payway: {
        label: 'Selecciona el método de pago'
      },
      card: {
        label: 'Introduce los datos de la tarjeta',
        iframe: {
          title: 'Formulario con los datos de la tarjeta de crédito para completar'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Formulario con número de teléfono a cumplimentar'
        },
        status: {
          pending: {
            statusText: 'En espera de confirmación',
            header: 'Confirmar pago en la aplicación',
            descriptionText1: 'Confirma el pago en la aplicación Visa Mobile o en una aplicación bancaria con servicio Visa Mobile habilitado',
            descriptionText2: 'Una vez confirmada la operación en la aplicación, espera el resultado del pago',
            descriptionText3: 'Cancelación de operación en curso',
            cancelButton: 'Cancelar pago'
          },
          success: {
            statusText: '¡Gracias por su compra!',
            descriptionText: 'Pago realizado con éxito'
          },
          error: {
            statusText: 'Asegúrate de que dispones de fondos suficientes en la tarjeta de pago utilizada para realizar el pago con Visa Mobile.',
            descriptionText: 'Pago rechazado'
          }
        },
        apps: 'Aplicaciones que admiten pagos con Visa Mobile',
        help: '¿Necesitas ayuda?',
        info: 'Más información',
        secure: 'Pago seguro con encriptación SSL',
        modal2: {
          header: 'Para confirmar el pago, abre la aplicación Visa Mobile o la aplicación del banco donde tengas habilitado el servicio Visa Mobile',
          listText1: 'tienes instalada la aplicación Visa Mobile o habilitado el servicio Visa Mobile en la aplicación móvil de tu banco',
          listText2: 'tienes habilitadas notificaciones en tu aplicación Visa Mobile o en tu aplicación bancaria con servicio Visa Mobile habilitado',
          listText3: 'el teléfono que has facilitado es correcto: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Asegúrate también de que...'
        }
      },
      click2pay: {
        loaderText: 'Estamos buscando tu dirección de correo electrónico<br>en <strong>Clic para pagar</strong>',
        saveCard: {
          text1: 'Guarda tu tarjeta',
          text2: 'Tus datos se guardarán de forma segura en <strong>Mastercard Click to Pay</strong>',
          link: 'Más información'
        },
        email: {
          labelForClick2Pay: 'Dirección de correo electrónico para notificaciones de pago'
        },
        cardSelect: {
          selected: 'Seleccionado',
          select: 'Seleccionar',
          payOther: 'Ingrese los detalles de la tarjeta',
          back: 'Atras'
        },
        cardSelected: {
          header: 'Usar una tarjeta guardada en Click to Pay',
          date: 'válido hasta',
          buttonChange: 'Cambiar'
        },
        codeForm: {
          description: 'Encontramos su dirección de correo electrónico en Click to Pay.',
          codeSentEmail: 'Ingrese el código de un solo uso que enviamos a {email} para iniciar sesión en su cuenta.',
          codeSentPhone: 'Ingrese el código SMS de un solo uso que enviamos a {phone} para iniciar sesión en su cuenta.',
          codeLabel: 'Ingrese el código',
          codePlaceholder: 'ingresar código',
          codeLabelFlow: 'Código',
          codeSendLabel: 'Enviar nuevo código',
          codeSendAriaLabel: 'vía',
          codeSendAriaLabel2: 'o',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'correo electrónico',
          trustedLabel: 'Agrega este dispositivo a tu lista de confianza<br><span>La próxima vez reconoceremos este dispositivo y pagarás sin iniciar sesión</span>',
          buttonSubmit: 'Siguiente',
          buttonChangeCard: 'Ingresar detalles de la tarjeta'
        },
        info: {
          description: 'El pago con tarjeta está cifrado y es seguro. La transacción se autorizará mediante 3DSecure en el sitio web del banco.'
        },
        loginForm: {
          emailLabel: 'Ingrese su dirección de correo electrónico para iniciar sesión en Click to Pay',
          emailPlaceholder: 'dirección de correo electrónico',
          emailLabelFlow: 'Correo electrónico',
          buttonSubmit: 'Iniciar sesión',
          buttonBack: 'Volver'
        },
        newUserForm: {
          header: 'Guardar tarjeta en Click to Pay',
          description: 'Necesitamos algunos detalles más para guardar su tarjeta.',
          emailLabel: 'Ingrese la dirección de correo electrónico',
          emailPlaceholder: 'dirección de correo electrónico',
          emailLabelFlow: 'Correo electrónico',
          countryLabel: 'País',
          firstnameLabel: 'Nombre',
          firstnamePlaceholder: 'nombre',
          firstnameLabelFlow: 'Nombre',
          lastnameLabel: 'Apellido',
          lastnamePlaceholder: 'apellido',
          lastnameLabelFlow: 'Apellido',
          phoneLabel: 'Número de teléfono',
          phonePlaceholder: 'número de teléfono',
          phoneLabelFlow: 'Teléfono',
          countryCodePlaceholder: 'código',
          countryCodeLabelFlow: 'Código',
          TrustedLabel: 'Agrega este dispositivo a tu lista de confianza<br><span>La próxima vez reconoceremos este dispositivo y pagarás sin iniciar sesión</span>',
          TermsLabel: 'Al continuar, aceptas <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Términos de uso</a> y entiendes que tus datos serán procesados de acuerdo con las <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank ">Política de privacidad</a>Mastercard.',
          c2pInfo: 'Click to Pay utilizará esta información para verificarlo y enviar códigos de verificación a este número. Es posible que se apliquen cargos por mensajes/datos.',
          buttonSubmit: 'Guardar y pagar',
          buttonBack: 'Pagar sin guardar tu tarjeta'
        },
        profile: {
          header: 'Inicie sesión para hacer clic para pagar',
          buttonChangeEmail: 'Cambiar dirección de correo electrónico'
        },
        modal: {
          title: 'Haga clic para pagar - información',
          header: 'Pague de forma rápida y segura, sin tener que volver a escribir los datos de su tarjeta en el nuevo estándar Click to Pay',
          text1: 'Al pagar con tarjeta, busque el logotipo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> y pague con su tarjeta de pago rápida y cómodamente.',
          text2: 'Click To Pay es un nuevo estándar de pago con tarjeta compatible con Visa y Mastercard.',
          text3: 'Puedes pagar con la tarjeta de tu cuenta Click to Pay en tiendas de Polonia y del extranjero, sin copiar los datos de la tarjeta.',
          text4: 'Los datos se guardan de forma segura en Visa o Mastercard. Toda la información está completamente cifrada.',
          text5: 'Durante los pagos posteriores, Click to Pay reconocerá su dirección de correo electrónico, para que pueda pagar inmediatamente con sus tarjetas guardadas.',
          text6: 'Además, confirmas los pagos en tu banco, lo que garantiza total seguridad.',
          ProcessError: 'Se produjo un error al ejecutar el pago Click to Pay. Realice un pago con tarjeta estándar.'
        }
      },
      email: {
        label: 'Introduce la dirección e-mail',
        flowLabel: 'E-mail',
        tip: 'Necesitamos tu dirección e-mail para poder enviarte los mensajes sobre el estado de los pagos.'
      },
      terms: {
        header: 'Consentimientos y declaraciones',
        selectAll: 'Marcar todos',
        fieldNotRequired: 'Campo opcional',
        partnerMarketingTextLess: '¿Quieres recibir alertas por e-mail, SMS o teléfono de las mejores ofertas, promociones, concursos y otros eventos interesantes organizados por nosotros o nuestros Socios?',
        partnerMarketingTextMore: '¡Prometemos no hacer spam! Si nuestros mensajes te cansan, puedes retirar el consentimiento en cualquier momento.',
        tradeMarketingTextLess: '¿Quieres que transmitamos tu e-mail o teléfono a nuestros Socios de confianza para que puedan ofrecerte información y ofertas interesantes?',
        tradeMarketingTextMore: 'Garantizamos que nuestros Socios son empresas reconocidas que, como nosotros, evitan el spam y puedes retirar el consentimiento en cualquier momento.'
      },
      termsLabelDefault: 'He leído y acepto <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Descargar el archivo con los términos y condiciones del servicio" rel="noreferrer">Términos y condiciones</a> de prestación de servicios de pago y <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Descargar el archivo de política de privacidad de Autopay S.A.">la Política de privacidad</a>. Quiero que el servicio se realice inmediatamente y estoy consciente de que, en caso de desistimiento, no se me reembolsarán los costes de los servicios realizados a mi solicitud antes del desistimiento.',
      termsReccuring: 'Si deseas realizar el pago, acepta <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Descargar el archivo de los términos y condiciones del servicio" rel="noreferrer">los Términos y condiciones</a> y <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Descargar el archivo de política de privacidad de Autopay S.A.">la Política de Privacidad</a> de Autopay S.A., autoriza la ejecución inmediata del servicio de pago y autoriza el cargo cíclico en tu tarjeta.',
      termsReccuringMore1: 'Acepto la ejecución inmediata del servicio y estoy consciente de que, en caso de desistimiento, no se me reembolsarán los costes de los servicios realizados a mi solicitud antes del desistimiento.',
      termsReccuringMore2: 'Acepto el cargo cíclico por Autopay S.A. en mi tarjeta de pago las operaciones a {receiverName}. Las condiciones de cargo cíclico en la tarjeta se definen en <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Descargar el archivo de los términos y condiciones del servicio" rel="noreferrer">los Términos y condiciones</a> de prestación de servicios de pago. Declaro que me he familiarizado con los plazos, la frecuencia y las normas para determinar los importes que se cargarán en mi tarjeta de pago, así como la forma de revocar este consentimiento, acordados por mí con {receiverName}. Para cualquier consulta relativa a los cargos cíclicos en mi tarjeta de pago, me pondré en contacto con {receiverName}.',
      mwfClauseLess: 'Si quieres realizar un pago, acepta los términos y <a href="{regulationUrl}" target="_blank" title="Términos y condiciones del servicio" rel="noreferrer">Términos y Condiciones</a> y <a href="{privacyPolicyUrl}" target="_blank" title="Política de privacidad de Autopay S.A.">la política de privacidad de Autopay S.A.</a>, el consentimiento para iniciar la transacción en tu banco a través de Autopay S.A. y la transferencia de los detalles de la transacción al destinatario, así como la solicitud de procesamiento inmediato del servicio de pago.',
      mwfClauseMore: 'Quiero que el servicio se preste inmediatamente y estoy consciente que no recibiré un reembolso de los gastos incurridos en caso de desistir del contrato.',
      mwfClauseARIA: 'Si desea realizar un pago, acepte el Reglamento y la política de privacidad de Autopay S.A., consienta iniciar transacciones en su banco a través de Autopay S.A. y entregar los detalles de la transacción al beneficiario y solicitar la ejecución inmediata del servicio de pago. Quiero que el servicio se preste de inmediato y sé que no recibiré un reembolso en caso de desistimiento del contrato. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Descarga el archivo con los términos del servicio</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Descarga el archivo desde la política de privacidad de Autopay S.A.</a>',
      paymentInfo: 'La orden de pago se presentará a través de Autopay S.A. con domicilio en Sopot y se procesará de acuerdo con las condiciones de tu banco. Una vez seleccionado el banco, autorizarás el pago.',
      changePaywayLink1: 'Todavía puedes',
      changePaywayLink2: 'cambiar el método de pago',
      linkMore: 'MÁS',
      linkShowMoreTitle: 'Mostrar información adicional',
      linkLess: 'MENOS',
      linkShowLessTitle: 'Ocultar información adicional',
      submitButton: {
        visaMobile: 'Pagar con',
        fastTransfer: 'Generar detalles de la transferencia',
        mwf: 'Aceptar y pagar',
        pis: 'Aceptar e iniciar el pago',
        auto: 'Ordenar pago recurrente',
        autoPayment: 'Orden de pago',
        other: 'Pagar',
        orange: 'Confirmar',
        c2pNext: 'Siguiente',
        c2pAccept: 'Acepto',
        c2pSaveAndPay: 'Guardar y pagar'
      }
    },
    frData: {
      pageTitle: 'Datos detallados para la transferencia',
      header: 'Solicita una transferencia utilizando los datos facilitados',
      nrbLabel: 'Cuenta del Destinatario',
      nameLabel: 'Nombre del Destinatario',
      addressLabel: 'Dirección del Destinatario',
      titleLabel: 'Concepto de la Transferencia',
      amountLabel: 'Importe de la Transferencia',
      timeLabel: 'Ejecutar la Transacción hasta',
      linkCopy: 'Copiar',
      linkGetPdf: 'Descargar PDF con datos',
      linkGetPdfTitle: 'Se abre en una nueva ventana',
      linkGoToBank: 'Iniciar sesión en el banco',
      info: 'Inicia sesión en tu cuenta bancaria. Copia los datos en la transferencia. Es importante que los datos sean los mismos: no modifiques el importe, la moneda, el concepto, etc.'
    },
    processing: {
      pageTitle: 'Página de espera para el procesamiento de transacciones',
      loaderAnimation: 'Animación mostrada mientras espera',
      header: 'Estamos preparando tu página de pagos.',
      description: 'Espera un momento más...',
      info: {
        header: '¡Recuerda!',
        description: 'No cierres la página una vez finalizada la transacción: si lo haces, tu dinero no se transferirá al destinatario.'
      },
      loaderText: 'Transferencia de datos en curso.<br>Tardaremos como máximo unos 30 segundos.'
    }
  },
  thankYou: {
    pageTitle: 'Información sobre el resultado de la transacción.',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Volver al sitio del socio'
    },
    negative: {
      header: 'El pago no se procesado debido a la falta de autorización de la transacción.',
      description: 'Si todavía quieres utilizar la oferta de este vendedor o proveedor de servicios, vuelve a su sitio web, selecciona el producto o el servicio y realiza el pago a través del método de tu preferencia.'
    },
    pending: {
      header: 'Gracias por ordenar el pago a través de la pasarela de pago.',
      description: 'La verificación del estado de la transacción está en curso. Una vez completado el proceso, recibirás información sobre el estado del pago en el buzón de tu correo electrónico.'
    },
    positive: {
      header: 'Gracias, tu pago ha sido procesado.',
      description: 'La confirmación se enviará a la dirección de correo electrónico que nos has facilitado.'
    }
  },
  regulationsApproval: {
    header: 'Aceptar la transacción'
  },
  wait: {
    pageTitle: 'Página de espera'
  },
  sessionTimeout: {
    pageTitle: 'Página después del final de la sesión',
    header: 'El tiempo para completar el pago ha expirado',
    description: 'Puedes volver al sitio del socio en cualquier momento, volver a hacer el pedido o utilizar el servicio y realizar el pago a través del método de tu preferencia.'
  },
  paywayError: {
    pageTitle: 'El canal de pago seleccionado no está disponible',
    header: 'Desafortunadamente, el método de pago seleccionado no está disponible actualmente.',
    description: 'Vuelva a la página de socios y vuelva a intentarlo más tarde.'
  },
  loader: {
    defaultAnimation: 'Animación mostrada mientras se carga la página',
    slider: 'Control deslizante con textos cambiantes',
    text1: 'En Autopay, estamos comprometidos con la neutralidad de carbono. Tú también lo haces utilizando nuestros servicios.',
    text2: 'En Autopay, vamos al trabajo en bicicleta, reduciendo la emisión de gases de efecto invernadero en la atmósfera. Tú también lo haces utilizando nuestros servicios.',
    text3: 'En Autopay, nos alimenta la energía verde. También a ti, cuando utilizas nuestros servicios.',
    text4: 'En Autopay, reducimos el consumo de papel. Tú también lo haces utilizando nuestros servicios.',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Detalles',
    headerAriaLabel: 'Detalles de la transacción',
    amountLabel: 'Importe a pagar',
    receiverLabel: 'Destinatario',
    orderLabel: 'Número de pedido',
    amountOryginalCurrencyLabel: 'Importe en {currency}',
    currencyRateLabel: 'Tipo de cambio',
    feeLabel: 'Comisión por procesamiento de la transacción',
    customerNumberLabel: 'Número de Cliente',
    invoiceNumberLabel: 'Número de factura',
    accountNumberLabel: 'Número de cuenta',
    paywayLabel: 'Método de pago',
    paywayName: 'Transferencia bancaria en línea',
    linkBack: 'Volver a la tienda',
    linkBackTitle: 'Salir de la transacción y volver a la tienda',
    linkMore: 'más',
    linkLess: 'menos',
    toggleBtnUnfoldAriaLabel: 'Expandir detalles del pedido',
    toggleBtnFoldAriaLabel: 'Contraer detalles del pedido'
  },
  error: {
    pageTitle: 'Página de error',
    linkBackUrlSet: 'Volver al sitio del socio',
    linkBackUrlNotSet: 'Ir a la página de ayuda',
    sessionTimeout: {
      title: 'El tiempo para completar el pago ha expirado',
      message: 'Puedes volver al sitio del socio en cualquier momento, volver a hacer el pedido o utilizar el servicio y realizar el pago a través del método de tu preferencia.'
    },
    notFoundError: {
      pageTitle: 'Página de error 404 - página no encontrada',
      title: 'Parece que has navegado demasiado lejos y no ha sido posible encontrar el sitio que quieres visitar.',
      message: 'Posibles causas: el sitio no existe o ha sido eliminado, está en curso de mantenimiento, la dirección del sitio es incorrecta, se ha producido un error técnico.'
    },
    generalError: {
      title: 'Lo sentimos',
      message: 'No podemos procesar tu transferencia en este momento.'
    },
    errFieldNotFound: {
      title: 'Lo sentimos',
      message: ''
    },
    errBadClientSource: {
      title: 'Lo sentimos',
      message: ''
    },
    nrParametersError: {
      title: 'Lo sentimos',
      message: 'No podemos procesar tu transferencia en este momento.'
    },
    transactionOutdated: {
      title: 'El tiempo para completar el pago ha expirado.',
      message: 'Vuelve a ordenar la transacción.'
    },
    linkValidityTimeOutdated: {
      title: 'El tiempo para completar el pago ha expirado.',
      message: 'Vuelve a ordenar la transacción.'
    },
    transactionValidityTimeOutdated: {
      title: 'El tiempo para completar el pago ha expirado.',
      message: 'Vuelve a ordenar la transacción.'
    },
    multiplyTransaction: {
      title: 'Lo sentimos',
      message: 'La transacción ya existe y está pendiente de pago.'
    },
    transactionCanceled: {
      title: 'Lo sentimos',
      message1: 'La transacción ha sido cancelada.',
      message2: 'Vuelve a ordenar la transacción.'
    },
    multiplyPaidTransaction: {
      title: 'Lo sentimos',
      message: 'La transacción ya está pagada.'
    },
    transactionReject: {
      title: 'Lo sentimos',
      message1: 'Oops, algo salió mal.',
      message2: 'Ya estamos arreglando la pasarela...'
    },
    bankDisabled: {
      title: 'Lo sentimos',
      message: 'El banco seleccionado no está disponible en este momento.'
    },
    bankTemporaryMaintenance: {
      title: 'Lo sentimos',
      message: 'No podemos procesar tu transferencia en este momento.'
    },
    insufficientStartAmount: {
      title: 'Lo sentimos',
      message: 'Se ha superado el límite del importe de la transferencia.'
    },
    startAmountOutOfRange: {
      title: 'Lo sentimos',
      message: 'Se ha superado el límite del importe de la transferencia.'
    },
    nonAccountedLimitExceeded: {
      title: 'Lo sentimos',
      message: 'El pago no podrá ser procesado porque el vendedor alcanzó el límite de ventas mensual.'
    }
  },
  confirmation: {
    pageTitle: 'Información sobre el progreso del pago',
    linkBack: 'Voy a volver al sitio',
    linkBackTimeout: 'Regresar al sitio web del socio en ',
    incorrectAmount: {
      header: 'Cantidad incorrecta',
      description: 'No transferimos su pago al destinatario porque el monto era incorrecto.',
      description2: 'Haga clic en el botón para regresar a la página y rehacerla. Recuerda no cambiar ningún dato.'
    },
    multiplePaid: {
      header: 'Depósito doble',
      description: 'El destinatario recibió su pago dos veces. No te preocupes, recibirás tu reembolso.',
      description2: 'Contactar al destinatario y proporcionarle la confirmación del pago.'
    },
    negative: {
      header: 'Sin pago',
      description: 'Se produjo un error al procesar su pago. Pero no se pierde nada.',
      description2: 'Haga clic en el botón para regresar a la página y rehacerla.'
    },
    notFound: {
      header: 'Sin pago',
      description: 'Se produjo un error al procesar su pago. Pero no se pierde nada.',
      description2: 'Haga clic en el botón para regresar a la página y rehacerla.'
    },
    outdated: {
      header: 'Fin de los tiempos',
      description: 'No transferimos su pago al destinatario.',
      description2: 'Haga clic en el botón para regresar a la página y rehacerla.'
    },
    outdatedPaidNotRefunded: {
      header: 'Fin de los tiempos',
      description: 'No transferimos su pago al destinatario. Si se ha retirado dinero de su cuenta, no se preocupe, recibirá su reembolso en unos días.',
      description2: 'Haga clic en el botón para regresar a la página y rehacerla.'
    },
    outdatedPaidRefunded: {
      header: 'Fin de los tiempos',
      description: 'No transferimos su pago al destinatario. Si el dinero fue retirado de tu cuenta, no te preocupes, ya lo hemos reembolsado.',
      description2: 'Haga clic en el botón para regresar a la página y rehacerla.'
    },
    pending: {
      header: 'En progreso',
      description: 'Estamos procesando su pago. Le avisaremos cuando llegue al destinatario.'
    },
    positive: {
      header: 'Pagado',
      visaVideo: 'Video publicitario reproducido después de una transacción exitosa',
      description: 'Hemos transferido sus fondos al destinatario.'
    }
  },
  messages: {
    accessForbidden: 'Acceso denegado',
    internalServerError: 'Se ha producido un error inesperado. Intenta nuevamente más tarde.',
    validators: {
      required: 'Campo obligatorio',
      pattern: 'Por favor proporcione un valor válido',
      emailRequired: 'La dirección e-mail es obligatoria.',
      emailNotValid: 'Tu dirección e-mail contiene un error. Introduce una dirección e-mail válida',
      emailNotRecognized: 'La dirección de correo electrónico proporcionada no está registrada',
      emailIdentityLookup: 'Error al verificar la dirección de correo electrónico',
      emailLocked: 'La dirección de correo electrónico proporcionada está bloqueada temporalmente en el sistema Mastercard',
      paywayRequired: 'Selecciona el método de pago',
      creditCardRequired: 'Complete todos los campos',
      phoneRequired: 'Por favor ingrese un número de teléfono válido',
      regulationRequired: 'Aceptar términos y condiciones',
      requiredConsent: 'Consentimiento obligatorio',
      codeNotValid: 'El código ingresado no es válido',
      codeAccountLocked: 'La cuenta ha sido bloqueada temporalmente en el sistema Mastercard'
    },
    validatorsAria: {
      required: 'Atención. El campo del formulario no ha sido completado. Campo requerido.',
      pattern: 'Atención. El campo contiene un valor no válido.',
      emailRequired: 'Atención. El campo de correo electrónico no ha sido completado. Por favor, introduce una dirección de correo electrónico válida.',
      emailNotValid: 'Atención. Su dirección de correo electrónico contiene un error. Por favor, introduce una dirección de correo electrónico válida.',
      emailNotRecognized: 'Atención. La dirección de correo electrónico proporcionada no está registrada.',
      emailIdentityLookup: 'Atención. La dirección de correo electrónico proporcionada no se pudo verificar',
      emailLocked: 'Atención. La dirección de correo electrónico proporcionada está bloqueada temporalmente en el sistema Mastercard.',
      paywayRequired: 'Atención. No se ha seleccionado el pago. Elige el método de pago.',
      creditCardRequired: 'Atención. Complete todos los campos con los datos de la tarjeta de crédito.',
      phoneRequired: 'Atención. El campo del número de teléfono contiene un error. Por favor, introduzca un número de teléfono válido.',
      codeNotValid: 'Atención. El código proporcionado no es válido.',
      codeAccountLocked: 'Atención. La cuenta ha sido bloqueada temporalmente en el sistema Mastercard.'
    },
    success: {
      copy: '¡Copiado!',
      codeSent: '¡El código ha sido enviado!'
    },
    errors: {
      getPdf: '¡No se ha podido descargar el archivo PDF!',
      copy: '¡No se ha podido copiar!',
      thankYouUpdate: '¡No se ha podido guardar la dirección e-mail!',
      paywayInactive: 'Por razones técnicas, por el momento no es posible realizar una transferencia del banco seleccionado. Selecciona otro banco u otro método de pago.',
      groupInactive: 'En este momento, es imposible realizar una transferencia utilizando el grupo {groupName}. Seleccione otro método de pago.',
      googlePayInactive: 'Lamentablemente, el pago con Google Pay no está disponible en tu dispositivo.',
      applePayInactive: 'El pago con Apple Pay está disponible para los usuarios del navegador Safari o dispositivos con el sistema operativo iOS.',
      codeNotSent: '¡Error al reenviar el código!',
      codeCounterExceeded: 'Se ha excedido el número de intentos permitidos. Introduce los datos de tu tarjeta manualmente o utiliza otro método de pago.'
    }
  },
  modal: {
    closeButton: 'Cerrar',
    confirmButton: 'Entiendo'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Pago recurrente',
        description: 'Introduce los datos de la tarjeta que utilizarás para el pago. Los pagos automáticos se tomarán de su tarjeta más tarde.',
        labelInitWithRefund: 'Pago de verificación',
        labelInitWithRefundOrange: 'Agregar una tarjeta de pago',
        labelInitWithPayment: 'Pago con tarjeta',
        descriptionInitWithRefund: 'Por favor, introduzca los datos de la tarjeta que desea verificar para futuros pagos. En el siguiente paso, cobraremos 1 PLN de esta tarjeta para verificar la exactitud de los datos. Una vez completada la verificación, este importe se le devolverá.',
        descriptionInitWithPayment: 'Por favor, introduzca los datos de la tarjeta que utilizará para realizar la transacción y verifíquela al mismo tiempo para futuros pagos.'
      },
      blik: {
        label: 'BLIK',
        description: 'Paga al instante con el código BLIK, sin inicio de sesión, sin registro'
      },
      card: {
        label: 'Pago con tarjeta',
        description: 'Paga con tu tarjeta de pago'
      },
      pbl: {
        label: 'Transferencia en línea',
        description: 'Seleccione el banco desde el que desea realizar un pago',
        descriptionVerify: 'Verificación de identidad en línea',
        descriptionServiceVerify: ''
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Visa Pago móvil'
      },
      wallet: {
        label: 'Google&nbsp;Pay | Apple&nbsp;Pay',
        description: 'Paga sin necesidad de iniciar la sesión en la banca en línea'
      },
      installments: {
        label: 'Pago a plazos',
        description: 'Paga tus compras mediante cómodas cuotas'
      },
      alior: {
        descriptionInfo1: 'Paga tus compras mediante cómodas cuotas',
        descriptionInfo2: '(verificar detalles)',
        aliorModal: {
          text1: 'Dependiendo de la configuración del sitio web donde realizas la compra, puedes adquirir productos a plazos en las siguientes variantes:',
          text2: '<ul><li>10 cuotas 0%</li><li>20 cuotas 0%</li><li>de 3 a 48 cuotas con un costo mensual del 1% (según el ejemplo representativo dado para una transacción determinada)</li></ul>'
        }
      },
      otp: {
        label: 'Pagar más tarde',
        description: 'Compra ahora paga después',
        descriptionInfo1Mwf: 'Paga tus facturas más tarde: hasta 45 días seguidos o en varias cuotas iguales',
        descriptionInfo1Ecommerce: 'Pague más tarde: hasta 45 días seguidos o en varias cuotas iguales',
        descriptionInfo2Mwf: 'Información sobre el intermediario de crédito y el costo',
        descriptionInfo2Ecommerce: 'Información de costos',
        labelPayka: 'Payka',
        descriptionPayka: 'Paga tus facturas más tarde: en un solo pago en hasta 30 días o en 4 plazos iguales <a href="" id="paykaShowInModal">Información sobre el intermediario de crédito y el costo</a>',
        paykaModalMwf: {
          header1: 'Información sobre el intermediario de crédito',
          text1: 'Autopay S.A. con domicilio en Sopot actúa como intermediario de crédito de Paytree S.A. con domicilio en Gdańsk (Prestamista), autorizado a presentar la oferta de crédito al cliente y a redirigirlo al sitio web del Prestamista, incluida la redirección a la solicitud de crédito. Para más información, visite: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Información sobre el costo',
          text2: 'Pague más tarde: hasta 45 días seguidos o en varias cuotas iguales. Datos de costes para un ejemplo representativo: Amortización en 45 días TAE 0%; Amortización a plazos TAE 86,83%. Detalles de la oferta en: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Información sobre el costo',
          text2: 'Pague más tarde: hasta 45 días seguidos o en varias cuotas iguales. Datos de costes para un ejemplo representativo: Amortización en 45 días TAE 0%; Amortización a plazos TAE 86,83%. Detalles de la oferta en: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        }
      },
      blikPayLater: {
        label: 'BLIK Pagar después',
        description: 'El servicio está disponible en Millenium Bank y VeloBank.',
        modal: {
          header1: 'Compra ahora, paga en 30 días',
          header2: 'código BLIK',
          text2: 'Introduce el código BLIK y te ofrecemos un límite de compra de hasta 4000 PLN',
          header3: 'Compras productos y los pagas más tarde.',
          text3: 'Tienes tiempo para comprobar si todo te conviene',
          header4: 'Pagas en 30 días sin coste alguno',
          text4: 'También podrás devolver tus compras dentro del tiempo especificado por la tienda'
        }
      },
      companyPayments: {
        label: 'Pagos de la empresa',
        description: 'Aplaza 30 días la fecha de pago de las compras de tu empresa'
      },
      transfer: {
        label: 'Detalles de la transferencia',
        description: 'Realiza una transferencia utilizando los datos facilitados'
      },
      other: {
        label: 'Otros',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Lo sentimos. Actualmente no podemos procesar tu transferencia. El banco seleccionado no está disponible de momento.',
        blockMultipleTransactions: 'Lo sentimos. La transacción ya existe y está pendiente de pago.',
        blockPaidTransactions: 'Lo sentimos. La transacción ya está pagada. No es posible volver a procesar el pago.',
        canceledTransactions: 'La transacción que estás intentando pagar ha sido cancelada y no está disponible. Por favor, inicia una nueva transacción.',
        declined: 'Autorización denegada.',
        generalError: 'Se ha producido un problema de conexión temporal. No podemos procesar tu transferencia en este momento. Por favor, vuelve más tarde.',
        insufficientStartAmount: 'Lo sentimos. Se ha superado el límite del importe de la transferencia.',
        internalServerError: 'Oopsss.. algo falló :(. Ya estamos solucionando el problema. Intenta nuevamente más tarde.',
        nonAccountedLimitExceeded: 'El pago no podrá ser procesado porque el vendedor alcanzó el límite de ventas mensual.',
        outdatedError: 'El tiempo para completar el pago ha expirado...',
        paid: 'Transacción completada con éxito.',
        paywayLoaded: 'Se ha cargado la página de transferencia rápida',
        paywaylistLoaded: 'Se ha cargado la página de selección del método de pago'
      }
    }
  },
  maintenance: {
    text1: 'Debido a la limpieza de Año Nuevo de la pasarela de pago de Autopay, en este momento no es posible realizar los pagos. Disculpa las molestias.',
    text2: 'La pasarela volverá a funcionar desde el 16 de enero a las 10:00.',
    text3: 'Gracias por tu comprensión.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Ingrese los detalles del propietario de la cuenta',
      flowLabel: 'Propietario de la cuenta',
      placeholder: 'Ingrese los detalles del propietario de la cuenta'
    },
    nip: {
      label: 'Ingrese su número de identificación fiscal',
      flowLabel: 'NIP',
      placeholder: 'Ingrese su número de identificación fiscal'
    },
    customerEmail: {
      label: 'Introduce tu dirección de correo electrónico',
      flowLabel: 'Correo electrónico',
      placeholder: 'Introduce tu dirección de correo electrónico'
    }
  }
}
