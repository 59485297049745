<template>
  <div class="container">
    <div class="paywall">
      <div class="columns loader">
        <section class="sidebar-column">
          <order-details-side :transaction="transaction"
                              :payway="payway"
                              :visibility="visibilityOptions"
                              :show-button="true" />
        </section>
        <section class="main-column">
          <div class="content">
            <div class="payment-processing-loader-wrapper">
              <payment-processing-loader />
              <div class="loader-text">
                <form @submit.prevent="submit" v-form:form novalidate>
                  <h2>{{ $t('regulationsApproval.header') }}</h2>
                  <div class="agreements">

                    <!-- regulamin jako checkbox -->
                    <div v-if="regulation && !isRegulationHidden">
                      <div class="form-row" :class="{'is-error': isErrorForField('regulationApproved')}">
                        <input type="checkbox"
                               id="regulationApproved"
                               name="regulationApproved"
                               @keydown.13.prevent
                               v-model="regulationApproved"
                               requiredTerms />
                        <label for="regulationApproved">
                            <span v-if="isErrorForField('regulationApproved')" class="error">
                              {{ getFieldErrorText('regulationApproved') }}
                            </span>
                          <span>
                              <span class="required">* </span>
                              <regulation-text v-if="isRegulationLabelSet(regulation)" :regulation="regulation"></regulation-text>
                            <!-- TODO: określić co w przypadku jeśli regulamin nie ma labela -->
                              <span v-if="!isRegulationLabelSet(regulation)" v-html="$t('paywall.form.termsLabelDefault', {regulationUrl: regulation.url, privacyPolicyUrl: privacyPolicyUrl})"></span>
                            </span>
                        </label>
                      </div>
                    </div>

                    <!-- regulamin jako oświadczenie -->
                    <div v-if="regulation && isRegulationHidden">
                      <div class="form-row">
                        <p class="elem-hidden-for-aria" v-html="regulation.inputLabel"></p>
                        <p aria-hidden="true" class="terms-clause">
                          <regulation-text v-if="isRegulationLabelSet(regulation)" :regulation="regulation"></regulation-text>
                          <!-- TODO: określić co w przypadku jeśli regulamin nie ma labela -->
                          <span v-if="!isRegulationLabelSet(regulation)" v-html="$t('paywall.form.termsLabelDefault', {regulationUrl: regulation.url, privacyPolicyUrl: privacyPolicyUrl})"></span>
                        </p>
                      </div>
                    </div>

                    <!-- submit -->
                    <div class="submit-wrapper">
                      <div>
                        <button class="btn" :class="{'inactive': isFormInvalid()}" :disabled="isProcessing">
                          <span>{{ getSubmitButtonText() }}</span>
                        </button>
                      </div>
                      <p v-html="$t('paywall.form.paymentInfo')"></p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentProcessingLoader from '@/base/components/Payment/Processing/ProcessingLoader'
import RegulationText from '@/base/components/Payment/Paywall/RegulationText.vue'
import OrderDetailsSide from '@/base/components/OrderDetails/Side'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { PAYMENT_SERVICE_TYPE } from '@/base/const/payment.const'
import { PaymentClass } from '@/base/class/payment.class'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { MOBILE_STATUS } from '@/base/const/mobileEvents.const'
import FormMixin from '@/base/mixins/form.mixin'
import { REGULATION_TYPE } from '@/base/const/regulation.const'
import { ToolsClass } from '@/base/class/tools.class'
const TOGGLE_ELEMENTS_LIST = {
  showMwfTermsMore: 'showMwfTermsMore'
}

export default {
  name: 'RegulationsApprovalForm',
  mixins: [
    FormMixin
  ],
  components: {
    OrderDetailsSide,
    PaymentProcessingLoader,
    RegulationText
  },
  data () {
    return {
      showMwfTermsMore: false,
      showReccuringTermsMore: false,
      toggleElementList: TOGGLE_ELEMENTS_LIST
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.payment.processing,
      transaction: state => state.payment.paymentData?.transaction || null,
      regulation: state => state.payment.form.regulation,
      payway: state => {
        if (!state.payment.paymentData) {
          return null
        }

        if (state.payment.paymentData.chosenPaywayId) {
          return state.payment.paymentData.paywayGroups[0].payways.find(payway => payway.id === state.payment.paymentData.chosenPaywayId)
        }
        if (state.payment.paymentData.chosenPayway) {
          return state.payment.paymentData.chosenPayway
        }
      },
      visibilityOptions: state => state.payment.paymentData?.visibilityOptions || null,
      serviceType: state => state.payment.paymentData?.serviceType || null,
      isServiceTypeEcommerce: state => state.payment.paymentData?.serviceType === PAYMENT_SERVICE_TYPE.ecommerce,
      isServiceTypeMwf: state => state.payment.paymentData?.serviceType === PAYMENT_SERVICE_TYPE.mwf
    }),
    ...mapGetters({
      lang: 'getCurrentLanguage'
    }),
    privacyPolicyUrl: function () {
      return PaymentClass.getPrivacyPolicyLinkByLang(this.lang)
    },
    isRegulationHidden: function () {
      if (!this.regulation) {
        return true
      }
      return this.regulation.checkedAndHidden
    },
    isRegulationReccuring: function () {
      return this.regulation && this.regulation.type === REGULATION_TYPE.recurring
    },
    regulationApproved: {
      get () {
        return this.$store.state.payment.form.regulationApproved
      },
      set (value) {
        if (value) {
          this.SET_PAYMENT_FORM_REGULATION_APPROVED(true)
          if (this.isRegulationReccuring) {
            this.SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER('ACCEPTED')
          }
        } else {
          this.SET_PAYMENT_FORM_REGULATION_APPROVED(false)
          this.SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER(null)
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_PAYMENT_FORM_REGULATION_APPROVED',
      'SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER',
      'SET_ERROR_DATA'
    ]),
    ...mapActions([
      'sendPaymentUpdateTrx',
      'disableSessionTimer'
    ]),
    toggleElement (name) {
      this[name] = !this[name]
      this.toggledElement = TOGGLE_ELEMENTS_LIST[name]
    },
    isRegulationLabelSet (regulation) {
      return !!regulation.inputLabel && String(regulation.inputLabel).length
    },
    getSubmitButtonText () {
      if (this.isServiceTypeMwf) {
        return this.$t('paywall.form.submitButton.mwf')
      }
      return this.$t('paywall.form.submitButton.pis')
    },
    isFormInvalid () {
      if (this.form) {
        return this.form.invalid
      }
      return false
    },
    submit () {
      if (this.regulation && this.isRegulationHidden) {
        this.SET_PAYMENT_FORM_REGULATION_APPROVED(true)
      }
      this.form.validateForm()
      if (this.form.invalid) {
        this.scrollToError()
        return
      }
      const data = {
        paymentToken: null,
        marketing: null
      }
      ToolsClass.scrollToElementTop(document.querySelector('header'))
      this.disableSessionTimer()
      this.sendPaymentUpdateTrx(data)
        .catch(error => {
          this.SET_ERROR_DATA({
            errorCode: error.body.errorCode,
            errorUrl: error.body.errorUrl
          })
        })
    }
  },
  mounted () {
    MobileAppClass.postMessageSuccess(MOBILE_STATUS().paywayLoaded)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/_processing-loader.scss";
@import "./src/base/assets/scss/components/PaywallForm.scss";

.elem-hidden-for-aria {
  font-size: 0;
  line-height: 0;
  background-color: transparent;

  a.form-text-link {
    color: transparent;
    padding: 0;
  }
}
</style>
