<template>
  <div id="yourcx.insert" class="yourcx-survey"></div>
</template>

<script>
import { mapGetters } from 'vuex'

const VECTRA_PROD_SID_LIST = [
  170313
]
const ORANGE_PROD_SID_LIST = [
  101730,
  101731,
  101728,
  101729,
  101732,
  101733,
  100934,
  100935,
  100666,
  100936,
  100937,
  100368,
  103152,
  103153,
  100667,
  100422,
  100661,
  100369,
  100668,
  100396,
  100394,
  100665,
  100370,
  154966,
  905243 // testowy na STG
]

export default {
  name: 'YourCxSurvey',
  computed: {
    ...mapGetters({
      currentLanguage: 'getCurrentLanguage'
    })
  },
  props: {
    serviceId: {
      type: String,
      required: true
    }
  },
  mounted () {
    if (!window.yourcx) {
      return
    }
    window.yourcx('dimension', 'page.type', 'payment.gate.thank_you_page_positive')

    if (ORANGE_PROD_SID_LIST.includes(parseInt(this.serviceId, 10))) {
      window.yourcx('survey', 'af44c4c56f385c43f2529f9b1b018f6a', { product: 'insert' })
    } else if (VECTRA_PROD_SID_LIST.includes(parseInt(this.serviceId, 10))) {
      window.yourcx('survey', '2bd235c31c97855b7ef2dc8b414779af', { product: 'insert' })
    } else {
      window.yourcx('survey', this.$VUE_YOURCX_CONFIG.product.insert.surveyId[this.currentLanguage], { product: 'insert' })
    }
  }
}
</script>
