import { PAYWAY_IMAGES, PAYWAY_IMAGES_SRC, PAYWAY_PSD2_TYPES } from '@/base/const/payway.const'
import { LANGUAGES } from '@/base/const/lang.const'
import { PRIVACY_POLICY_LINK } from '@/base/const/links.const'

export class PaymentClass {
  /**
   * Metoda sprawdzająca, czy dana płatność jest typu PIS
   * @param {number} [paywayId]
   * @param {array} [paywayList]
   * @returns {boolean}
   */
  static isPaywayTypePis (paywayId, paywayList = []) {
    if (!paywayId || typeof paywayId !== 'number') {
      return false
    }
    const payway = paywayList.find(payway => payway.id === paywayId)
    if (!payway) {
      return false
    }
    return payway.typeForPsd2 === PAYWAY_PSD2_TYPES.pis
  }

  /**
   * Metoda sprawdzająca, czy mamy logo dla kanału płatności
   * @param {object} payway
   * @returns {boolean}
   */
  static isPaywayImage (payway) {
    if (!payway || typeof payway !== 'object') {
      return false
    }

    let result = !!payway.iconUrl && !!payway.iconUrl.length
    PAYWAY_IMAGES.forEach(item => {
      if (item.id.includes(payway.id)) {
        result = true
      }
    })
    return result
  }

  /**
   * Metoda zwracająca ścieżkę do obrazka z logo kanału płatności
   * @param {object} payway
   * @returns {string}
   */
  static getPaywayImage (payway) {
    if (!payway || typeof payway !== 'object') {
      return ''
    }
    let imgUrl = payway.iconUrl
    PAYWAY_IMAGES.forEach(item => {
      if (item.id.includes(payway.id)) {
        imgUrl = `${PAYWAY_IMAGES_SRC}${item.img}`
      }
    })
    return imgUrl
  }

  /**
   * Metoda zwracająca link do polityki prywatności dla podanego języka
   * @param {string} [lang]
   * @returns {*}
   */
  static getPrivacyPolicyLinkByLang (lang) {
    if (lang && PRIVACY_POLICY_LINK[lang]) {
      return PRIVACY_POLICY_LINK[lang]
    }
    return PRIVACY_POLICY_LINK[LANGUAGES.en]
  }
}
