import { Raven } from 'vue-raven'
import { apiClass } from '../../class/api.class'
import { ENDPOINTS } from '../../const/endpoints.const'
import { PAYMENT_CLIENT_SOURCE } from '@/base/const/payment.const'
import { LANG_DEFAULT, LANGUAGES_ACTIVE_LIST } from '@/base/const/lang.const'
import {
  CARD_ID_LIST,
  PAYWAY_GROUP_MODEL,
  PAYWAY_GROUPS_FRONT_API_MAP,
  PAYWAY_GROUPS_TYPES,
  paywayGroupsList
} from '@/base/const/payway.const'
import { ToolsClass } from '@/base/class/tools.class'
import { PAYWALL_MODE, PAYWALL_PAYWAY_METHOD, PAYWALL_THEME } from '@/base/const/paywall.const'
import { CookieClass } from '@/base/class/cookie.class'
import { COOKIE_LANG } from '@/base/const/cookies.const'
import { REGULATION_TYPE } from '@/base/const/regulation.const'
import { PROMOTION_TYPES } from '@/base/const/promotion.const'
import { HTTP_STATUS } from '@/base/const/http.const'

const state = {
  loading: false,
  processing: false,
  selectOther: false,
  apiToken: null,
  transRemoteID: null,
  continueHash: null,
  contextName: null,
  paymentData: null,
  paywaysLanguageData: null,
  selectedPaywayGroupType: null,
  selectedPaywayGroupId: null,
  paywallTheme: PAYWALL_THEME.light,
  paywallView: false,
  paywallMode: PAYWALL_MODE.main,
  paywayMethod: PAYWALL_PAYWAY_METHOD.single,
  form: {
    serviceID: null,
    paywayID: null,
    transRemoteID: null,
    customerEmail: null,
    regulation: null,
    regulationAcceptanceId: null,
    regulationApproved: null,
    recurringAcceptanceStateAfter: null,
    walletType: null,
    visaMobile: null,
    widgetData: {
      bmUserId: null,
      bmUserIdHash: null
    }
  },
  error: {
    invalidFields: null
  },
  session: {
    active: true,
    refreshing: null,
    currentTimestamp: +new Date()
  },
  rateLimitExceeded: false
}

const mutations = {
  SET_PAYMENT_LOADING (state, value) {
    state.loading = value
  },
  SET_PAYMENT_PROCESSING (state, value) {
    state.processing = value
  },
  SET_PAYMENT_API_TOKEN (state, value) {
    state.apiToken = value
  },
  SET_PAYMENT_CONTEXT_NAME (state, value) {
    state.contextName = value
  },
  SET_PAYMENT_FORM_VISAMOBILE (state, value) {
    state.form.visaMobile = value
  },
  SET_PAYMENT_FORM_EMAIL (state, value) {
    state.form.customerEmail = value
  },
  SET_PAYMENT_FORM_PAYWAY_ID (state, value) {
    state.form.paywayID = value
  },
  SET_PAYMENT_FORM_REGULATION_ID (state, value) {
    state.form.regulationAcceptanceId = value
  },
  SET_PAYMENT_FORM_REGULATION (state, data) {
    state.form.regulation = data
  },
  SET_PAYMENT_FORM_REGULATION_APPROVED (state, value) {
    state.form.regulationApproved = value
  },
  SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER (state, value) {
    state.form.recurringAcceptanceStateAfter = value
  },
  SET_PAYMENT_FORM_WALLET_TYPE (state, value) {
    state.form.walletType = value
  },
  SET_PAYMENT_FORM_SERVICE_ID (state, value) {
    state.form.serviceID = value
  },
  SET_PAYMENT_FORM_TRANS_REMOTE_ID (state, value) {
    state.form.transRemoteID = value
  },
  SET_PAYMENT_DATA (state, data) {
    state.paymentData = data
  },
  SET_PAYWAYS_LANGUAGE_DATA (state, data) {
    state.paywaysLanguageData = data
  },
  SET_PAYMENT_TRANS_REMOTE_ID (state, value) {
    state.transRemoteID = value
  },
  SET_PAYMENT_CONTINUE_HASH (state, value) {
    state.continueHash = value
  },
  SET_PAYMENT_TRANSACTION_PAYWAY_GROUPS (state, data) {
    state.paymentData.paywayGroups = data
  },
  SET_PAYMENT_ERROR_INVALID_FIELDS (state, data) {
    state.error.invalidFields = data
  },
  SET_SESSION_TIMER_ACTIVE (state, value) {
    state.session.active = value
  },
  SESSION_REFRESH_IN_PROGRESS (state, data) {
    state.session.refreshing = data
  },
  SET_CURRENT_TIMESTAMP (state, data) {
    state.session.currentTimestamp = data
  },
  SET_SELECT_OTHER (state, data) {
    state.selectOther = data
  },
  SET_PAYWAY_GROUP_TYPE (state, value) {
    state.selectedPaywayGroupType = value
  },
  SET_PAYWAY_GROUP_ID (state, value) {
    state.selectedPaywayGroupId = value
  },
  SET_PAYMENT_PAYWALL_VIEW (state, value) {
    state.paywallView = value
  },
  SET_PAYMENT_PAYWALL_MODE (state, value) {
    state.paywallMode = value
  },
  SET_PAYWAY_METHOD (state, value) {
    state.paywayMethod = value
  },
  SET_PAYWALL_THEME (state, value) {
    state.paywallTheme = value
  },
  SET_RATE_LIMIT_EXCEEDED (state, value) {
    state.rateLimitExceeded = value
  }
}

const actions = {
  getPaywaysLanguageData ({ commit, getters, state }) {
    if (!state.paymentData || !state.paymentData.paywayGroups) {
      return Promise.resolve()
    }
    const payways = []

    state.paymentData.paywayGroups.forEach(group => {
      group.payways.forEach(payway => {
        payways.push(payway.id)
      })
    })

    const params = [
      payways.map(paywayId => `paywayIds=${paywayId}`).join('&'),
      `serviceId=${state.paymentData.serviceID}`,
      `language=${getters.getCurrentLanguage}`
    ]
    const url = ENDPOINTS.languageData + `?${params.join('&')}`
    return apiClass.get(url)
      .then(response => {
        commit('SET_PAYWAYS_LANGUAGE_DATA', response.body.paywayLanguageData)
      })
      .catch(error => {
        console.log('error', error)
      })
  },
  setPaymentLanguage ({ dispatch, getters }, data) {
    const queryParams = ToolsClass.getQueryParams()
    const languagesList = getters.getAvailableLanguagesList
    const paymentLang = data.languageData?.languageChosen
    const cookieLang = CookieClass.getCookie(COOKIE_LANG)
    const browserLang = navigator.language.substring(0, 2).toUpperCase()
    const queryLang = queryParams.lang?.toUpperCase()

    // 1. Jeśli mamy język z URLa oraz dostępną listę języków - zawsze ustawiamy język z URLa
    if (Array.isArray(languagesList) && queryLang && languagesList.includes(queryLang)) {
      dispatch('setLanguage', queryLang)
      return
    }

    // 2. Jeśli mamy język z transakcji oraz dostępną listę języków - zawsze ustawiamy język z transakcji
    if (Array.isArray(languagesList) && paymentLang && languagesList.includes(paymentLang)) {
      dispatch('setLanguage', paymentLang)
      return
    }

    // 3. Jeśli nie mamy listy i/lub języka z transakcji, sprawdzamy cookie
    if (cookieLang && LANGUAGES_ACTIVE_LIST.includes(cookieLang)) {
      dispatch('setLanguage', cookieLang)
      return
    }

    // 4. Jeśli nie mamy info z cookie, sprawdzamy język przeglądarki
    if (browserLang && LANGUAGES_ACTIVE_LIST.includes(browserLang)) {
      dispatch('setLanguage', browserLang)
      return
    }

    // 5. ostatecznie ustawiamy domyślny język
    dispatch('setLanguage', LANG_DEFAULT)
  },
  getPaymentStartTrx ({ commit, state, dispatch }, { apiToken }) {
    commit('SET_RATE_LIMIT_EXCEEDED', false)
    commit('SET_PAYMENT_LOADING', true)
    commit('SET_PAYMENT_API_TOKEN', apiToken)
    commit('SET_PAYMENT_ERROR_INVALID_FIELDS', null)

    const headers = {
      'Referer-Paywall': document.referrer
    }
    const data = {
      apiToken: apiToken,
      contextName: state.contextName
    }

    return apiClass.post(ENDPOINTS.start, data, { headers })
      .then(response => {
        const data = processPaymentData(commit, dispatch, response.body.data)
        return setPaywall(commit, data)
      })
      .catch(error => {
        if (error.status === HTTP_STATUS.RATE_LIMIT_EXCEEDED) {
          commit('SET_RATE_LIMIT_EXCEEDED', true)
          return Promise.reject(error)
        }

        if (error === 'psd2Data') {
          return Promise.reject(error)
        }

        if (error.body) {
          dispatch('setPaymentLanguage', error.body.data)
        }
        commit('SET_PAYMENT_LOADING', false)
        commit('SET_PAYMENT_PAYWALL_VIEW', false)
        if (error.body && error.body.invalidFields) {
          commit('SET_PAYMENT_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('payment.js getPaymentStartTrx Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  getPaymentVisadataTrx ({ commit, state, dispatch }, { apiToken, idTransRemote }) {
    commit('SET_PAYMENT_LOADING', true)
    commit('SET_PAYMENT_API_TOKEN', apiToken)
    commit('SET_PAYMENT_TRANS_REMOTE_ID', idTransRemote)
    commit('SET_PAYMENT_ERROR_INVALID_FIELDS', null)

    const headers = {
      'Referer-Paywall': document.referrer
    }
    const data = {
      apiToken: apiToken,
      idTransRemote: idTransRemote,
      contextName: state.contextName
    }

    return apiClass.post(ENDPOINTS.visadata, data, { headers })
      .then(response => {
        processPaymentData(commit, dispatch, response.body.data)
        commit('SET_PAYMENT_LOADING', false)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_PAYMENT_LOADING', false)
        commit('SET_PAYMENT_PAYWALL_VIEW', false)
        if (error.body && error.body.invalidFields) {
          commit('SET_PAYMENT_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('payment.js getPaymentVisadataTrx Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  getPaymentRegulationsApprovalStart ({ commit, state, dispatch }, { apiToken }) {
    commit('SET_PAYMENT_LOADING', true)
    commit('SET_PAYMENT_API_TOKEN', apiToken)
    commit('SET_PAYMENT_ERROR_INVALID_FIELDS', null)

    const data = {
      apiToken: apiToken,
      contextName: state.contextName
    }

    return apiClass.post(ENDPOINTS.regulationsApprovalStart, data)
      .then(response => {
        const data = processPaymentData(commit, dispatch, response.body.startResponseData)
        dispatch('setPaymentActivePayway', data.chosenPayway)
        commit('SET_PAYMENT_PAYWALL_MODE', PAYWALL_MODE.regulationsApproval)
        commit('SET_PAYMENT_LOADING', false)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_PAYMENT_LOADING', false)
        if (error.body && error.body.invalidFields) {
          commit('SET_PAYMENT_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('payment.js getPaymentRegulationsApprovalStart Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  getPaymentThankYouTrx ({ commit, state }, { apiToken }) {
    commit('SET_PAYMENT_LOADING', true)
    commit('SET_PAYMENT_API_TOKEN', apiToken)
    commit('SET_PAYMENT_ERROR_INVALID_FIELDS', null)

    const data = {
      apiToken: apiToken
    }

    return apiClass.post(ENDPOINTS.thankYou.getData, data)
      .then(response => {
        const data = response.body
        data.transaction = {
          customerFee: data.customerFee,
          orderId: data.orderId,
          payAmount: data.payAmount.value,
          payAmountCurrency: data.payAmount.currency,
          receiverNameForFront: data.receiverNameForFront,
          customerNumber: data.customerNumber,
          invoiceNumber: data.invoiceNumber,
          accountNumber: data.accountNumber
        }
        commit('SET_PAYMENT_DATA', data)
        commit('SET_PAYMENT_FORM_SERVICE_ID', data.serviceID)
        commit('SET_PAYMENT_FORM_TRANS_REMOTE_ID', data.transRemoteID)
        return Promise.resolve()
      })
      .catch(error => {
        if (error.body && error.body.invalidFields) {
          commit('SET_PAYMENT_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('payment.js getPaymentThankYouTrx Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_PAYMENT_LOADING', false)
      })
  },
  getPaymentContinueTrx ({ commit, state, dispatch, getters }, { idTransRemote, continueHash, sid }) {
    commit('SET_PAYMENT_LOADING', true)
    commit('SET_PAYMENT_ERROR_INVALID_FIELDS', null)
    commit('SET_PAYMENT_TRANS_REMOTE_ID', idTransRemote)
    commit('SET_PAYMENT_CONTINUE_HASH', continueHash)

    const headers = {
      'Referer-Paywall': document.referrer
    }
    const data = {
      idTransRemote: idTransRemote,
      continueHash: continueHash,
      contextName: state.contextName
    }

    if (sid) {
      data.sid = sid
    }

    if (ToolsClass.isVersionSDKSet()) {
      data.versionSDK = ToolsClass.getVersionSDK()
    }
    if (ToolsClass.isScreenTypeSet()) {
      data.screenType = ToolsClass.getScreenType()
    }

    return apiClass.post(ENDPOINTS.continue, data, { headers })
      .then(response => {
        commit('SET_PAYMENT_API_TOKEN', response.body.data.apiToken)
        const data = processPaymentData(commit, dispatch, response.body.data)
        const paywayList = getters.getPaywayList
        if (paywayList) {
          const paywayObject = paywayList.find(payway => payway.id === data.chosenPaywayId)
          if (paywayObject) {
            dispatch('setPaymentActivePayway', paywayObject)
          }
        }
        return setPaywall(commit, data)
      })
      .catch(error => {
        if (error.status === HTTP_STATUS.RATE_LIMIT_EXCEEDED) {
          commit('SET_RATE_LIMIT_EXCEEDED', true)
          return Promise.reject(error)
        }

        if (error === 'psd2Data') {
          return Promise.reject(error)
        }

        commit('SET_PAYMENT_LOADING', false)
        if (error.body && error.body.invalidFields) {
          commit('SET_PAYMENT_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('payment.js getPaymentContinueTrx Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  getPaymentRegulationsApprovalContinueTrx ({ commit, state, dispatch }, { idTransRemote, continueHash }) {
    commit('SET_PAYMENT_LOADING', true)
    commit('SET_PAYMENT_ERROR_INVALID_FIELDS', null)
    commit('SET_PAYMENT_TRANS_REMOTE_ID', idTransRemote)
    commit('SET_PAYMENT_CONTINUE_HASH', continueHash)

    const headers = {
      'Referer-Paywall': document.referrer
    }
    const data = {
      idTransRemote: idTransRemote,
      continueHash: continueHash,
      contextName: state.contextName
    }

    if (ToolsClass.isVersionSDKSet()) {
      data.versionSDK = ToolsClass.getVersionSDK()
    }
    if (ToolsClass.isScreenTypeSet()) {
      data.screenType = ToolsClass.getScreenType()
    }

    return apiClass.post(ENDPOINTS.continue, data, { headers })
      .then(response => {
        const data = processPaymentData(commit, dispatch, response.body.data)
        const paywayList = getters.getPaywayList
        const paywayObject = paywayList.find(payway => payway.id === data.chosenPaywayId)
        if (paywayObject) {
          dispatch('setPaymentActivePayway', paywayObject)
        }
        commit('SET_PAYMENT_API_TOKEN', data.apiToken)
        commit('SET_PAYMENT_PAYWALL_MODE', PAYWALL_MODE.regulationsApproval)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_PAYMENT_LOADING', false)
        if (error.body && error.body.invalidFields) {
          commit('SET_PAYMENT_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('payment.js getPaymentRegulationsApprovalContinueTrx Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  getPaymentWaitData ({ commit, state, dispatch }, { apiToken, idTransRemote }) {
    commit('SET_PAYMENT_LOADING', true)
    commit('SET_PAYMENT_API_TOKEN', apiToken)
    commit('SET_PAYMENT_TRANS_REMOTE_ID', idTransRemote)
    commit('SET_PAYMENT_ERROR_INVALID_FIELDS', null)

    const data = {
      apiToken: apiToken,
      idTransRemote: idTransRemote
    }

    return apiClass.post(ENDPOINTS.wait.getData, data)
      .then(response => {
        const data = response.body.data
        commit('SET_PAYMENT_PAYWALL_VIEW', false)
        commit('SET_PAYMENT_DATA', data)

        // jeśli start z WL z kanałem PISowym i brak danych psd2 -> przekierowanie na potykacz PISowy
        if ([PAYMENT_CLIENT_SOURCE.psd2Data].includes(data.clientSource)) {
          commit('SET_PAYMENT_PAYWALL_VIEW', false)
          if (!data.psd2Data) {
            return Promise.reject(new Error('psd2Data'))
          }
        }

        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_PAYMENT_LOADING', false)
        commit('SET_PAYMENT_PAYWALL_VIEW', false)
        if (error.body && error.body.invalidFields) {
          commit('SET_PAYMENT_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('payment.js getPaymentWaitData Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  sendPaymentThankYouUpdate ({ commit, state }) {
    commit('SET_PAYMENT_PROCESSING', true)

    const data = {
      apiToken: state.apiToken,
      email: state.form.customerEmail
    }
    return apiClass.post(ENDPOINTS.thankYou.update, data)
      .then(response => {
        const data = response.body
        return Promise.resolve(data.returnUrl)
      })
      .catch(error => {
        Raven.captureMessage('payment.js sendPaymentThankYouUpdate Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_PAYMENT_PROCESSING', false)
      })
  },
  sendPaymentUpdateTrx ({ commit, state, getters }, { paymentToken, marketing, requiredParams }) {
    commit('SET_PAYMENT_PROCESSING', true)
    commit('SET_PAYMENT_ERROR_INVALID_FIELDS', null)
    commit('SET_PAYMENT_PAYWALL_VIEW', false)

    const data = state.form
    data.apiToken = state.apiToken
    data.marketingAgreements = []
    data.requiredParams = requiredParams
    if (paymentToken) {
      data.paymentToken = paymentToken
    }
    if (marketing) {
      const marketingList = getters.getMarketingAgreementList
      Object.keys(marketing).forEach(key => {
        if (marketing[key]) {
          const agreement = marketingList.find(item => item.code === key)
          if (agreement) {
            data.marketingAgreements.push({
              code: key,
              language: agreement.language,
              agree: true
            })
          }
        }
      })
    }

    return apiClass.post(ENDPOINTS.updatePayway, data)
      .then(response => {
        const data = setDataTransaction(response.body.data)
        commit('SET_PAYMENT_DATA', data)
        if ([PAYMENT_CLIENT_SOURCE.frData, PAYMENT_CLIENT_SOURCE.visaMobileData].includes(data.clientSource)) {
          commit('SET_PAYMENT_PROCESSING', false)
        }
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_PAYMENT_PROCESSING', false)
        if (error.body && error.body.invalidFields) {
          commit('SET_PAYMENT_ERROR_INVALID_FIELDS', error.body.invalidFields)
        }
        Raven.captureMessage('payment.js sendPaymentUpdateTrx Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  sendPaymentRejectTrx ({ commit, state }) {
    commit('SET_PAYMENT_LOADING', true)
    commit('SET_PAYMENT_PAYWALL_VIEW', false)
    const data = {
      merchantId: 0,
      serviceId: state.paymentData.serviceID,
      idTransRemote: state.paymentData.transRemoteID
    }
    return apiClass.post(ENDPOINTS.reject, data)
      .then(response => {
        if (response.body.rejectResponseData && response.body.rejectResponseData.returnUrl) {
          return Promise.resolve(response.body.rejectResponseData.returnUrl)
        }
        return Promise.reject(response)
      })
      .catch(error => {
        commit('SET_PAYMENT_LOADING', false)
        return Promise.reject(error)
      })
  },
  setPaymentGroupSelected ({ commit, state }, group) {
    commit('SET_PAYWAY_GROUP_TYPE', group.type)
    commit('SET_PAYWAY_GROUP_ID', group.id)
    return Promise.resolve()
  },
  getApplePaySession ({ state }, url) {
    const data = {
      validationUrl: url,
      idTransRemote: state.paymentData.transaction.orderId
    }

    return apiClass.post(ENDPOINTS.appleSession, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(error => {
        Raven.captureMessage('payment.js getApplePaySession Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  getWalletData ({ state }) {
    const data = {
      serviceId: state.paymentData.serviceID,
      serviceUrl: state.paymentData.serviceUrl
    }

    return apiClass.post(ENDPOINTS.walletData, data)
      .then(response => {
        return Promise.resolve(response.body)
      })
      .catch(error => {
        Raven.captureMessage('payment.js getWalletData Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  getSessionRemainingTime ({ commit, state }) {
    commit('SESSION_REFRESH_IN_PROGRESS', true)
    const data = {
      apiToken: state.apiToken,
      idTransRemote: state.transRemoteID
    }
    return apiClass.post(ENDPOINTS.session.getRemainingTime, data)
      .then(response => {
        return Promise.resolve(response.body.sessionRemainingTime)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SESSION_REFRESH_IN_PROGRESS', false)
      })
  },
  disableSessionTimer ({ commit, state }) {
    commit('SET_SESSION_TIMER_ACTIVE', false)
  },
  refreshSession ({ commit, state }) {
    commit('SESSION_REFRESH_IN_PROGRESS', true)
    const data = {
      apiToken: state.apiToken,
      idTransRemote: state.paymentData.transRemoteID
    }
    return apiClass.post(ENDPOINTS.session.refresh, data)
      .then(response => {
        return Promise.resolve(response.body.sessionRemainingTime)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SESSION_REFRESH_IN_PROGRESS', false)
      })
  },
  rejectSession ({ state }) {
    const data = {
      apiToken: state.apiToken,
      idTransRemote: state.paymentData.transRemoteID
    }
    return apiClass.post(ENDPOINTS.session.reject, data)
      .then(() => {
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setPaymentActivePayway ({ commit, state, getters }, payway) {
    commit('SET_PAYMENT_FORM_REGULATION', null)
    commit('SET_PAYMENT_FORM_REGULATION_ID', null)
    commit('SET_PAYMENT_FORM_REGULATION_APPROVED', false)
    commit('SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER', null)

    if (!payway) {
      commit('SET_SELECT_OTHER', true)
      commit('SET_PAYMENT_FORM_PAYWAY_ID', null)
      return Promise.resolve()
    }

    commit('SET_SELECT_OTHER', false)
    commit('SET_PAYMENT_FORM_PAYWAY_ID', payway.id)

    if (payway.regulationIds && payway.regulationIds.length > 0) {
      const lang = getters.getCurrentLanguage
      const regulationType = payway.recurring ? REGULATION_TYPE.recurring : REGULATION_TYPE.main
      let regulation = null
      if (payway.regulationIds.length === 1) {
        regulation = state.paymentData.regulations.find(regulation => regulation.regulationId === payway.regulationIds[0] && regulation.gatewayId === payway.id)
        if (!regulation) {
          regulation = state.paymentData.regulations.find(regulation => regulation.regulationId === payway.regulationIds[0])
        }
      } else {
        regulation = state.paymentData.regulations.find(regulation => payway.regulationIds.includes(regulation.regulationId) && regulation.gatewayId === payway.id && regulation.language === lang.toUpperCase())
        if (!regulation) {
          regulation = state.paymentData.regulations.find(regulation => payway.regulationIds.includes(regulation.regulationId) && regulation.language === lang.toUpperCase())
        }
      }
      if (regulation) {
        commit('SET_PAYMENT_FORM_REGULATION_ID', regulation.regulationId)
        commit('SET_PAYMENT_FORM_REGULATION', regulation)
        // jeśli regulamin jest ukryty, oznaczamy go jako zaakceptowany
        if (regulation.checkedAndHidden) {
          commit('SET_PAYMENT_FORM_REGULATION_APPROVED', true)
        }
        if (regulationType === REGULATION_TYPE.recurring) {
          commit('SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER', 'ACCEPTED')
        }
      }
    }
    return Promise.resolve()
  },
  cancelVisaMobileTransaction ({ state }) {
    const data = {
      idTransRemote: state.paymentData.transRemoteID
    }
    return apiClass.postCards(ENDPOINTS.cards.visaMobile.cancel, data)
      .then(response => {
        if (response.body.status !== 'SUCCESS') {
          return Promise.reject(response.body.status)
        }
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  changePaywallMode ({ commit }, mode) {
    commit('SET_PAYMENT_PAYWALL_MODE', mode)
    return Promise.resolve()
  }
}

const getters = {
  getMarketingAgreementList (state, getters) {
    const langLower = getters.getCurrentLanguage.toLowerCase()
    const langUpper = getters.getCurrentLanguage.toUpperCase()
    return state.paymentData?.marketingAgreements?.filter(agreement => agreement.language === langLower || agreement.language === langUpper) || []
  },
  getPaywayList (state) {
    return generatePaywayList(state)
  },
  getPaymentGroupList (state, getters) {
    if (state.loading) {
      return []
    }
    return generatePaymentGroupsForGL30Response(state, getters)
  },
  isPaymentCardAvailable (state, getters) {
    const paywayList = getters.getPaywayList
    let result = false
    paywayList.forEach(payway => {
      if (CARD_ID_LIST.includes(payway.id)) {
        result = true
      }
    })
    return result
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}

/**
 * @param commit
 * @param dispatch
 * @param {object} data
 * @returns {*}
 */
const processPaymentData = (commit, dispatch, data) => {
  data = setDataTransaction(data)
  const customerEmail = data.additionalData.customerEmail
  if (customerEmail) {
    commit('SET_PAYMENT_FORM_EMAIL', customerEmail)
  }

  // jeśli grupy po nowemu z API
  if (data.paywayGroups) {
    data.paywayGroups.forEach(group => {
      group.id = ToolsClass.generateUUID()
    })
  }

  commit('SET_PAYMENT_DATA', data)
  commit('SET_PAYMENT_FORM_SERVICE_ID', data.serviceID)
  commit('SET_PAYMENT_TRANS_REMOTE_ID', data.transRemoteID)
  commit('SET_PAYMENT_FORM_TRANS_REMOTE_ID', data.transRemoteID)
  dispatch('setPaymentLanguage', data)
  return data
}

/**
 * @param {object} data
 * @returns {*}
 */
const setDataTransaction = (data) => {
  data.transaction = {
    customerFee: data.customerFee,
    orderId: data.additionalData.orderId,
    payAmount: data.payAmount,
    payAmountCurrency: data.payAmountCurrency,
    receiverNameForFront: data.receiverNameForFront,
    customerNumber: data.additionalData.customerNumber ? data.additionalData.customerNumber : null,
    invoiceNumber: data.additionalData.invoiceNumber ? data.additionalData.invoiceNumber : null,
    accountNumber: data.additionalData.accountNumber ? data.additionalData.accountNumber : null
  }
  return data
}

/**
 * @param commit
 * @param {object} data
 * @returns {Promise<never>|Promise<void>}
 */
const setPaywall = (commit, data) => {
  // jeśli start z WL z kanałem PISowym i brak danych psd2 -> przekierowanie na potykacz PISowy
  if ([PAYMENT_CLIENT_SOURCE.psd2Data].includes(data.clientSource)) {
    commit('SET_PAYMENT_PAYWALL_VIEW', false)
    if (!data.psd2Data) {
      return Promise.reject(new Error('psd2Data'))
    }
  }
  if ([PAYMENT_CLIENT_SOURCE.frData, PAYMENT_CLIENT_SOURCE.payways].includes(data.clientSource)) {
    if (data.chosenPaywayId) {
      commit('SET_PAYMENT_PAYWALL_MODE', PAYWALL_MODE.chosenPayway)
    }
    commit('SET_PAYMENT_LOADING', false)
    commit('SET_PAYMENT_PAYWALL_VIEW', true)
  }
  if ([PAYMENT_CLIENT_SOURCE.frData].includes(data.clientSource)) {
    commit('SET_PAYMENT_FORM_PAYWAY_ID', data.chosenPaywayId)
    commit('SET_PAYMENT_PAYWALL_VIEW', false)
  }
  return Promise.resolve()
}

/**
 * Metoda czyszcząca listę grup z grup pustych, bez kanałów płatności
 * @param {array} paywayGroups
 * @returns {*}
 */
const getNotEmptyPaywayGroups = (paywayGroups) => {
  const result = []
  paywayGroups.forEach(group => {
    if (group.payways.length) {
      group.active = group.payways.reduce((acc, payway) => {
        return acc || payway.active
      }, false)
      result.push(group)
    }
  })
  return ToolsClass.sortArrayByObjectProperty(result, 'order')
}

/**
 * Metoda generująca grupę promocyjną
 * @param getters
 * @param apiFrontType
 * @param group
 * @param state
 * @returns {*}
 */
const generatePromoGroup = (getters, apiFrontType, group, state) => {
  const newGroup = ToolsClass.deepExtend(PAYWAY_GROUP_MODEL)
  // newGroup.id = ToolsClass.generateUUID()
  newGroup.type = apiFrontType
  if (state.paywaysLanguageData) {
    const promoPaywayId = group.payways[0].id
    newGroup.label = state.paywaysLanguageData[promoPaywayId].promotionLanguageData.header
    newGroup.description = state.paywaysLanguageData[promoPaywayId].promotionLanguageData.tooltip
  }
  newGroup.isNew = group.promoType === PROMOTION_TYPES.novelty
  newGroup.isMastercard = group.promoType === PROMOTION_TYPES.mastercard
  newGroup.isVisaMobile = group.promoType === PROMOTION_TYPES.visamobile
  newGroup.isBlik = group.promoType === PROMOTION_TYPES.blik
  if (newGroup.isMastercard) {
    newGroup.iconUrl = '/web/img/icons/mastercard-visa.svg'
  }
  if (newGroup.isVisaMobile) {
    newGroup.iconUrl = '/web/img/icons/visa-mobile.svg'
  }
  return newGroup
}

/**
 * Metoda generująca listę kanałów płatności
 * @param state
 * @returns {*[]}
 */
const generatePaywayList = (state) => {
  let paywayList = []

  if (!state.paymentData || !state.paymentData.paywayGroups) {
    return paywayList
  }

  state.paymentData.paywayGroups.forEach(group => {
    paywayList = paywayList.concat(group.payways)
  })
  return paywayList
}

/**
 * Metoda generująca listę grup płatności wraz z kanałami płatności dla paywalla na podstawie Gateway Listy 3.0
 * @param state
 * @param getters
 * @returns {*}
 */
const generatePaymentGroupsForGL30Response = (state, getters) => {
  let paywayGroups = []
  let result = []
  let apiGroups = state.paymentData.paywayGroups
  let frontGroups = paywayGroupsList()
  apiGroups.forEach(group => {
    let apiFrontType = PAYWAY_GROUPS_FRONT_API_MAP[group.type]
    if (apiFrontType === PAYWAY_GROUPS_TYPES.promo) {
      const promoGroup = generatePromoGroup(getters, apiFrontType, group, state)
      result.push(Object.assign(promoGroup, group))
    } else {
      let findedGroup = frontGroups.find(frontGroup => frontGroup.type === apiFrontType)
      if (findedGroup) {
        let newGroup = Object.assign(findedGroup, group)
        newGroup.type = apiFrontType
        result.push(newGroup)
      }
    }
  })
  paywayGroups = getNotEmptyPaywayGroups(result)

  // wybrany kanał płatności
  if (state.paywallMode === PAYWALL_MODE.chosenPayway) {
    return paywayGroups.filter(group => {
      const choosenPayway = group.payways.find(payway => payway.id === state.paymentData.chosenPaywayId)
      if (choosenPayway) {
        group.payways = []
        group.payways.push(choosenPayway)
        return group
      }
    })
  }
  return paywayGroups
}
